import React from 'react';

import IconButtonTooltip from '@glass/web/components/Button/IconButtonTooltip';
import KeyboardArrowLeftRounded from '@glass/web/components/base/icons/KeyboardArrowLeftRounded';
import KeyboardArrowRightRounded from '@glass/web/components/base/icons/KeyboardArrowRightRounded';
import makeStyles from '@glass/web/modules/theme/makeStyles';

const useStyles = makeStyles()({
  button: {
    '@media (pointer: coarse)': {
      display: 'none',
    },
    alignSelf: 'center',
  },
});

const ICONS = {
  previous: <KeyboardArrowLeftRounded fontSize="large" />,
  next: <KeyboardArrowRightRounded fontSize="large" />,
};

export type SlideButtonProps = {
  onClick?: () => void;
  action: 'previous' | 'next';
};

function SlideButton({ onClick, action }: SlideButtonProps) {
  const { classes, cx } = useStyles();

  return (
    <IconButtonTooltip
      action={action}
      className={cx(classes.button)}
      color="inherit"
      onClick={onClick}
    >
      {ICONS[action]}
    </IconButtonTooltip>
  );
}

export default SlideButton;
