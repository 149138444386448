import { useMemo } from 'react';

import { LG, MD, SM, XL, XS } from '@glass/web/modules/theme/breakpointKeys';
import useMediaQuery from '@glass/web/modules/theme/useMediaQuery';
import useTheme from '@glass/web/modules/theme/useTheme';

const useBreakpoint = () => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only(XS));
  const isSm = useMediaQuery(theme.breakpoints.only(SM));
  const isMd = useMediaQuery(theme.breakpoints.only(MD));
  const isLg = useMediaQuery(theme.breakpoints.only(LG));
  const isXl = useMediaQuery(theme.breakpoints.only(XL));

  return useMemo(
    () => [XS, SM, MD, LG, XL][[isXs, isSm, isMd, isLg, isXl].findIndex((a) => a)],
    [isXs, isSm, isMd, isLg, isXl],
  );
};

export default useBreakpoint;
