import React, { createContext, useContext, useState, useMemo, ReactNode } from 'react';

interface CarouselContextType {
  currentSlide: number;
  setCurrentSlide: React.Dispatch<React.SetStateAction<number>> | null;
}

const defaultValue: CarouselContextType = {
  currentSlide: 0,
  setCurrentSlide: null,
};

const CarouselContext = createContext<CarouselContextType>(defaultValue);

export const useCarouselContext = () => {
  const context = useContext(CarouselContext);
  if (!context) {
    throw new Error('useCarouselContext must be used within a CarouselProvider');
  }
  return context;
};

export function CarouselProvider({
  children,
  initialSlideIdx,
}: {
  children: ReactNode;
  initialSlideIdx: number;
}) {
  const [currentSlide, setCurrentSlide] = useState(initialSlideIdx);
  const memoizedValue = useMemo(() => ({ currentSlide, setCurrentSlide }), [currentSlide]);

  return <CarouselContext.Provider value={memoizedValue}>{children}</CarouselContext.Provider>;
}
