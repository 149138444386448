import { useMemo } from 'react';

import type { BreakpointProps } from '@glass/web/components/Carousel/responsive';
import type { BreakpointTypes } from '@glass/web/modules/theme/breakpointKeys';
import useBreakpoint from '@glass/web/modules/theme/useBreakpoint';

/**
 * Get visible slides and carousel slides step based on the current media query breakpoint.
 *
 * @param {Theme} theme - The MUI theme object.
 * @param {BreakpointProps} mediaQueryList - A config object containing responsive properties for different breakpoints.
 * @returns {{
 *   visibleSlides: number;
 *   step: number;
 * }} - An object containing responsive properties for the current media query breakpoint.
 */
const useCarouselResponsiveProps = (responsiveProps: BreakpointProps) => {
  const breakpoint = useBreakpoint();
  return useMemo(
    () => responsiveProps[breakpoint as BreakpointTypes] || responsiveProps.default,
    [breakpoint, responsiveProps],
  );
};

export default useCarouselResponsiveProps;
