import { useMemo } from 'react';

import { createBatchedContentSeeds } from '@glass/shared/components/ResumeBuilder/templates/createContentSeed';

const useBatchedContentSeeds = ({ seedOverride, fallbackId, jSlug, iSlug }, length) =>
  useMemo(() => {
    return length > 0
      ? createBatchedContentSeeds({ seedOverride, fallbackId, iSlug, jSlug }, length)
      : [];
  }, [seedOverride, fallbackId, iSlug, jSlug, length]);

export default useBatchedContentSeeds;
