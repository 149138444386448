import React, { useMemo } from 'react';

import Carousel, { CarouselProps } from '@glass/web/components/Carousel/Carousel';
import { CarouselProvider } from '@glass/web/components/Carousel/context/CarouselContext';
import {
  BreakpointProps,
  DEFAULT_BREAKPOINT_PROPS,
} from '@glass/web/components/Carousel/responsive';
import useCarouselResponsiveProps from '@glass/web/components/Carousel/useCarouselResponsiveProps';
import { TypographyProps } from '@glass/web/components/base/Typography';
import makeStyles from '@glass/web/modules/theme/makeStyles';

import ResumeCarouselSlide from '@glass/shared/components/ResumeCarousel/ResumeCarouselSlide';
import useBatchedContentSeeds from '@glass/shared/components/hooks/useBatchedContentSeeds';
import { DAILY_SEED } from '@glass/shared/modules/random/seeds';
import useTemplateMetas from '@glass/shared/modules/resumes/hooks/useTemplateMetas';

const useStyles = makeStyles()({
  root: {
    width: '100%',
    display: 'flex',
  },
});

const loadingTemplateMetas = new Array(8).fill({}).map((_, index) => ({ slug: index.toString() }));

export interface TemplateMeta {
  id?: string;
  index?: number;
  slug?: string;
  subheader?: string;
  name?: string;
  updatedAt?: string;
}

export interface ResumeCarouselProps {
  value: string;
  ctaText?: string;
  clickDestination?: string;
  cellAlign: CarouselProps['cellAlign'];
  templateVariables?: Record<string, string>;
  fallbackId?: string;
  seedOverride?: string;
  imageSizes?: string;
  hasButtons?: boolean;
  loadingKey?: string;
  origin?: string;
  onChange?: (evt: React.MouseEvent, index: number, template?: string) => void;
  labelColor?: Exclude<TypographyProps['color'], undefined>;
  hasLabels?: boolean;
  hasSubheader?: boolean;
  jSlug?: string;
  iSlug?: string;
  shouldAutoPlay?: boolean;
  contentResumeSlug: string;
  priorityImages?: boolean;
  breakpointProps?: BreakpointProps;
}

function ResumeCarousel({
  contentResumeSlug,
  cellAlign = 'left',
  clickDestination,
  shouldAutoPlay,
  hasButtons,
  value,
  ctaText = 'CHOOSE',
  priorityImages,
  fallbackId = DAILY_SEED,
  onChange,
  jSlug,
  iSlug,
  imageSizes,
  seedOverride,
  origin,
  loadingKey,
  labelColor,
  hasLabels,
  hasSubheader,
  templateVariables,
  breakpointProps = DEFAULT_BREAKPOINT_PROPS,
}: ResumeCarouselProps) {
  const { classes } = useStyles();
  const { visibleSlides: slidesToShow, step } = useCarouselResponsiveProps(breakpointProps);

  const {
    // TODO: create useTemplateMetas hook in typescript
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    templateMetas: remoteTemplateMetas,
    loading: isLoading,
  }: {
    templateMetas?: TemplateMeta[];
    loading: boolean;
  } = useTemplateMetas({
    fallbackId,
    seedOverride,
    jSlug,
    iSlug,
    variables: templateVariables,
  });

  const templateMetas: TemplateMeta[] | undefined = useMemo(
    () => (isLoading ? loadingTemplateMetas : remoteTemplateMetas),
    [isLoading, remoteTemplateMetas],
  );

  const slideCount = templateMetas?.length;

  const batchedContentSeeds = useBatchedContentSeeds(
    { seedOverride, fallbackId, iSlug, jSlug },
    templateMetas?.length || 10,
  );

  const valueSlideIdx = useMemo(() => {
    const index = templateMetas?.findIndex(({ slug }) => slug === value);
    return typeof index === 'undefined' || index < 0 ? 0 : index;
  }, [templateMetas, value]);

  const slides = useMemo(
    () =>
      templateMetas?.map(({ id, subheader, slug, name, updatedAt }, idx) => {
        return (
          <ResumeCarouselSlide
            key={slug}
            cellAlign={cellAlign}
            clickDestination={clickDestination}
            contentResumeSlug={contentResumeSlug}
            ctaText={ctaText}
            hasLabels={hasLabels}
            hasSubheader={hasSubheader}
            id={id}
            idx={idx}
            imageSizes={imageSizes}
            isLoading={isLoading}
            labelColor={labelColor}
            labelName={name}
            loadingKey={loadingKey}
            name={name}
            origin={origin}
            priorityImages={priorityImages}
            seed={batchedContentSeeds[idx]}
            slideCount={slideCount}
            slidesToShow={slidesToShow}
            slug={slug}
            step={step}
            subheader={subheader}
            template={slug}
            updatedAt={updatedAt}
            value={value}
            onChange={onChange}
          />
        );
      }),
    [
      templateMetas,
      cellAlign,
      clickDestination,
      contentResumeSlug,
      ctaText,
      hasLabels,
      hasSubheader,
      imageSizes,
      isLoading,
      labelColor,
      loadingKey,
      onChange,
      origin,
      priorityImages,
      batchedContentSeeds,
      slideCount,
      slidesToShow,
      step,
      value,
    ],
  );

  if (!templateMetas) {
    return null;
  }

  return (
    <div className={classes.root}>
      <CarouselProvider initialSlideIdx={valueSlideIdx}>
        <Carousel
          breakpointProps={breakpointProps}
          cellAlign={cellAlign}
          hasButtons={hasButtons}
          shouldAutoPlay={shouldAutoPlay}
          slidesToShow={slidesToShow}
        >
          {slides}
        </Carousel>
      </CarouselProvider>
    </div>
  );
}

export default ResumeCarousel;
