import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import CircularProgress from '@glass/web/components/base/CircularProgress';
import makeStyles from '@glass/web/modules/theme/makeStyles';

import DelayFade from '@glass/shared/components/Loading/DelayFade';
import positionAbsoluteCenter from '@glass/shared/modules/theme/styles/positionAbsoluteCenter';

const useStyles = makeStyles()({
  spinner: positionAbsoluteCenter,
});

function Spinner({ loading, size }) {
  const { classes } = useStyles();
  const spinnerClasses = useMemo(() => ({ root: classes.spinner }), [classes.spinner]);

  return (
    <DelayFade appear={loading} in={loading}>
      <CircularProgress classes={spinnerClasses} size={size} />
    </DelayFade>
  );
}

Spinner.propTypes = {
  loading: PropTypes.bool,
  size: PropTypes.number,
};

Spinner.defaultProps = {
  loading: true,
};

export default React.memo(Spinner);
