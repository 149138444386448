import React, { MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';

import type { CarouselProps } from '@glass/web/components/Carousel/Carousel';
import { useCarouselContext } from '@glass/web/components/Carousel/context/CarouselContext';
import useCarouselLoad from '@glass/web/components/Carousel/useCarouselLoad';
import usePrefetchSlide from '@glass/web/components/Carousel/usePrefetchSlide';
import Paper from '@glass/web/components/base/Paper';
import Typography, { TypographyProps } from '@glass/web/components/base/Typography';
import { useIsPriorityLoaded } from '@glass/web/modules/loading/withPriorityEvents';
import resumeSize from '@glass/web/modules/resumes/templates/resumeSize';
import alpha from '@glass/web/modules/theme/alpha';
import { CENTER } from '@glass/web/modules/theme/constants';
import makeStyles from '@glass/web/modules/theme/makeStyles';

import AspectRatio from '@glass/shared/components/AspectRatio/AspectRatio';
import ButtonCenterHoverCta from '@glass/shared/components/Button/ButtonCenterHoverCta';
import Spinner from '@glass/shared/components/Loading/Spinner';
import ResponsiveResumeImage from '@glass/shared/components/ResumeBuilder/ResponsiveResumeImage';
import ResumeLinkContainer, {
  CONTACT_DEFAULT_VARIATION,
} from '@glass/shared/components/ResumeCarousel/ResumeLinkContainer';

const BACKDROP = 'blur(2px)';

const useStyles = makeStyles()((theme) => ({
  subheader: {
    position: 'absolute',
    top: 0,
    right: 0,
    color: theme.palette.common.white,
    backgroundColor: alpha(theme.palette.primary.main, 0.6),
    backdropFilter: BACKDROP,
    transform: 'translate(29.4%, -100%) rotateZ(45deg)',
    transformOrigin: 'bottom left',
    padding: theme.spacing(0.5, 3),
  },
  image: {
    width: '100%',
    height: '100%',
  },
  imageSelected: {
    border: '2px solid',
    borderColor: theme.palette.primary.light,
    borderRadius: theme.shape.borderRadius,
  },
  container: {
    cursor: 'pointer',
    display: 'block',
    width: '100%',
    padding: theme.spacing(0, 0.5),
  },
  innerContainer: {
    overflow: 'hidden',
    position: 'relative',
  },
  label: {
    fontWeight: theme.typography.fontWeightMedium as number,
    margin: theme.spacing(1, 0),
  },
  labelSelected: {
    fontWeight: theme.typography.fontWeightBold as number,
  },
  clickable: {
    cursor: 'pointer',
  },
}));

export interface ResumeCarouselSlideProps {
  labelName?: string;
  seed?: string;
  updatedAt?: string;
  clickDestination?: string;
  contentResumeSlug?: string;
  imageSizes?: string;
  subheader?: string;
  cellAlign: CarouselProps['cellAlign'];
  ctaText?: string;
  onChange?: (evt: React.MouseEvent, index: number, template?: string) => void;
  loadingKey?: string;
  origin?: string;
  showSelect?: boolean;
  hasLabels?: boolean;
  isLoading?: boolean;
  slideCount?: number;
  idx: number;
  hasSubheader?: boolean;
  slidesToShow?: number;
  labelColor?: Exclude<TypographyProps['color'], undefined>;
  priorityImages?: boolean;
  slug?: string;
  value?: string;
  step?: number;
  [stringKey: string]: unknown;
}

function ResumeCarouselSlide({
  labelName,
  seed,
  updatedAt,
  hasSubheader,
  contentResumeSlug,
  clickDestination = CONTACT_DEFAULT_VARIATION,
  imageSizes,
  subheader,
  cellAlign,
  ctaText = 'CHOOSE',
  onChange,
  loadingKey,
  origin,
  priorityImages = true,
  showSelect = true,
  hasLabels,
  slideCount = 1,
  slidesToShow = 1,
  idx,
  isLoading: loading,
  slug,
  value,
  step = 1,
  labelColor,
}: ResumeCarouselSlideProps) {
  const { currentSlide, setCurrentSlide } = useCarouselContext();
  const isPriorityIdleLoaded = useIsPriorityLoaded();

  const isCurrentSlide = currentSlide === idx;
  const isSelected = slug === value;

  useEffect(() => {
    if (isSelected) {
      setCurrentSlide(idx);
    }
  }, [isSelected, idx, setCurrentSlide]);

  const trackProps = useMemo(() => ({ label: `${origin as string}-carousel-slide` }), [origin]);

  const { classes, cx } = useStyles();
  const handleButtonClick = useCallback(
    (evt: MouseEvent) => onChange?.(evt, idx, slug),
    [slug, onChange, idx],
  );

  const isVisible = useMemo(() => {
    let isWithinRange = false;
    let startSlide;
    let endSlide;
    const offset = Math.floor(slidesToShow / 2);

    switch (cellAlign) {
      case 'center':
        startSlide = (currentSlide - offset + slideCount) % slideCount;
        endSlide = (currentSlide + offset) % slideCount;
        break;

      case 'right':
        startSlide = (currentSlide - slidesToShow + 1 + slideCount) % slideCount;
        endSlide = currentSlide;
        break;

      case 'left':
      default:
        startSlide = currentSlide;
        endSlide = (currentSlide + slidesToShow - 1) % slideCount;
        break;
    }

    if (startSlide <= endSlide) {
      isWithinRange = idx >= startSlide && idx <= endSlide;
    } else {
      // Handles wrap around scenario where range wraps from end back to the beginning
      isWithinRange = idx >= startSlide || idx <= endSlide;
    }

    return isCurrentSlide || isWithinRange;
  }, [cellAlign, isCurrentSlide, slidesToShow, currentSlide, slideCount, idx]);

  const prefetchSlide = usePrefetchSlide({
    slidesToPrefetch: step,
    currentSlide,
    slideCount,
    shouldStartPrefetch: isPriorityIdleLoaded,
  });

  const shouldLoad = isVisible || idx === prefetchSlide;
  const didLoad = useCarouselLoad({
    shouldLoad,
  });

  const [shouldReduce, setShouldReduce] = useState(priorityImages && isVisible);

  useEffect(() => {
    if (shouldReduce && !isVisible) {
      setShouldReduce(false);
    }
  }, [isVisible, shouldReduce]);

  return (
    (isPriorityIdleLoaded || isCurrentSlide || didLoad) && (
      <ResumeLinkContainer
        className={classes.container}
        clickDestination={clickDestination}
        template={slug}
        trackProps={trackProps}
        onClick={handleButtonClick}
      >
        <Paper elevation={isSelected ? 8 : undefined} square={!isSelected}>
          <AspectRatio
            allowOverflow={false}
            className={classes.innerContainer}
            height={resumeSize.height}
            id="aspectRatio"
            width={resumeSize.width}
          >
            {updatedAt ? (
              <>
                {!loading ? (
                  <ResponsiveResumeImage
                    className={cx(classes.image, {
                      [classes.imageSelected]: isSelected,
                    })}
                    contentResumeSlug={contentResumeSlug}
                    downscale={shouldReduce ? 0.65 : undefined}
                    fetchPriority={!shouldReduce ? 'high' : undefined}
                    load={didLoad || isVisible}
                    maxWidth={1200}
                    quality={shouldReduce ? 60 : undefined}
                    seed={seed}
                    sizes={imageSizes}
                    template={slug as string}
                    updatedAt={updatedAt}
                  />
                ) : null}
                {hasSubheader && subheader ? (
                  <Typography className={classes.subheader} display="inline">
                    {subheader}
                  </Typography>
                ) : null}
                {!isSelected && !loadingKey && showSelect ? (
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  <ButtonCenterHoverCta onClick={handleButtonClick}>{ctaText}</ButtonCenterHoverCta>
                ) : null}
                {loading || loadingKey === slug ? <Spinner /> : null}
              </>
            ) : null}
          </AspectRatio>
        </Paper>
        {hasLabels ? (
          <Typography
            align={CENTER}
            className={cx(classes.label, {
              [classes.labelSelected]: isSelected,
            })}
            color={labelColor}
            variant="h6"
          >
            {labelName}
          </Typography>
        ) : null}
      </ResumeLinkContainer>
    )
  );
}

export default React.memo(ResumeCarouselSlide);
