import PropTypes from 'prop-types';
import React from 'react';

import MuiButton from '@glass/web/components/base/Button';
import { CTA_BUTTON_ID } from '@glass/web/modules/content/pages/contentIds';
import alpha from '@glass/web/modules/theme/alpha';
import makeStyles from '@glass/web/modules/theme/makeStyles';

import positionAbsoluteCenter from '@glass/shared/modules/theme/styles/positionAbsoluteCenter';
import withTrackingContext from '@glass/shared/modules/tracking/withTrackingContext';

const useStyles = makeStyles()((theme) => ({
  buttonContainer: {
    ...positionAbsoluteCenter,
    transition: theme.transitions.create('opacity'),
    '@media (hover: none)': {
      opacity: 1,
    },
    opacity: 0,
    '&:hover': {
      opacity: 1,
    },
  },
  '@media (hover: none)': {
    button: {
      backgroundColor: alpha(theme.palette.primary.light, 0.4),
    },
  },
  button: {
    position: 'absolute',
    margin: 0,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%) scale(0.999);',
    backgroundColor: alpha(theme.palette.common.white, 0.5),
    backdropFilter: 'blur(2px)',
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.light, 0.4),
    },
  },
}));

const ButtonCenterHoverCta = React.forwardRef(
  ({ children, button: Button, onClick, color }, ref) => {
    const { classes } = useStyles();
    return (
      <div className={classes.buttonContainer}>
        <Button
          ref={ref}
          className={classes.button}
          color={color}
          size="large"
          variant="outlined"
          onClick={onClick}
        >
          {children}
        </Button>
      </div>
    );
  },
);

ButtonCenterHoverCta.propTypes = {
  button: PropTypes.oneOfType([PropTypes.node, PropTypes.object, PropTypes.func]),
  children: PropTypes.node,
  color: PropTypes.string,
  onClick: PropTypes.func,
};

ButtonCenterHoverCta.defaultProps = {
  button: MuiButton,
  color: 'primary',
};

export default React.memo(withTrackingContext(ButtonCenterHoverCta, CTA_BUTTON_ID));
