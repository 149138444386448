import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import Fade from '@glass/web/components/base/Fade';

function DelayFade({ in: inProp, appear, delayMs, children, unmountOnExit, mountOnEnter }) {
  const style = useMemo(
    () => ({
      transitionDelay: !inProp ? `${delayMs || 0}ms` : '0ms',
    }),
    [delayMs, inProp],
  );

  return (
    <Fade
      appear={appear}
      in={inProp}
      mountOnEnter={mountOnEnter}
      style={style}
      unmountOnExit={unmountOnExit}
    >
      {children}
    </Fade>
  );
}

DelayFade.propTypes = {
  appear: PropTypes.bool,
  children: PropTypes.node.isRequired,
  delayMs: PropTypes.number,
  in: PropTypes.bool,
  mountOnEnter: PropTypes.bool,
  unmountOnExit: PropTypes.bool,
};

DelayFade.defaultProps = {
  delayMs: 400,
};

export default React.memo(DelayFade);
