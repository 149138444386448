import calcImageSizes from '@glass/web/modules/imagesSize/calcImageSizes';
import { XS, SM, BreakpointTypes } from '@glass/web/modules/theme/breakpointKeys';

export type BreakpointProps = {
  default: {
    visibleSlides: number;
    step?: number;
  };
} & {
  [key in BreakpointTypes]?: {
    visibleSlides: number;
    step?: number;
  };
};

export const FULL_CAROUSEL_BREAKPOINT_PROPS: BreakpointProps = {
  [XS]: {
    visibleSlides: 1,
    step: 1,
  },
  [SM]: {
    visibleSlides: 2,
    step: 1,
  },
  default: {
    visibleSlides: 4,
    step: 2,
  },
};

export const DEFAULT_BREAKPOINT_PROPS: BreakpointProps = {
  default: {
    visibleSlides: 1,
    step: 1,
  },
};

export const imageSizes = calcImageSizes();
