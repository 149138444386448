import { useState, useEffect } from 'react';

interface UsePrefetchSlideProps {
  currentSlide: number;
  slidesToPrefetch: number;
  slideCount: number;
  shouldStartPrefetch: boolean;
}

/**
 * Custom hook for prefetching slides in a carousel component.
 *
 * @param {Object} options - The options for the prefetching.
 * @param {number} options.currentSlide - The index of the current slide.
 * @param {number} options.slideCount - The total number of slides.
 * @param {boolean} options.shouldStartPrefetch - Flag to determine if prefetching should start.
 * @param {number} options.slidesToPrefetch - The number of slides to prefetch ahead.
 *
 * @returns {number | null} - The index of the prefetch slide, or null if prefetching is not active.
 */
const usePrefetchSlide = ({
  currentSlide,
  slideCount,
  shouldStartPrefetch,
  slidesToPrefetch,
}: UsePrefetchSlideProps): number | null => {
  const [prefetchSlide, setPrefetchSlide] = useState<number | null>(null);

  useEffect(() => {
    if (!shouldStartPrefetch) return;

    const nextSlide = (currentSlide + slidesToPrefetch) % slideCount;
    setPrefetchSlide(nextSlide);
  }, [currentSlide, slideCount, shouldStartPrefetch, slidesToPrefetch]);

  return prefetchSlide;
};

export default usePrefetchSlide;
