import React, { Suspense, lazy, useMemo, useCallback } from 'react';

import SlideButton from '@glass/web/components/Carousel/SlideButton';
import SlidesSSRWrapper from '@glass/web/components/Carousel/SlidesSSRWrapper';
import { useCarouselContext } from '@glass/web/components/Carousel/context/CarouselContext';
import {
  BreakpointProps,
  DEFAULT_BREAKPOINT_PROPS,
} from '@glass/web/components/Carousel/responsive';
import useIsPriorityLoadedIdle from '@glass/web/modules/loading/useIsPriorityLoadedIdle';
import makeStyles from '@glass/web/modules/theme/makeStyles';

const LazyNukaCarousel = lazy(() => import('nuka-carousel'));

const useStyles = makeStyles()((theme) => ({
  carouselWrapper: {
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    ['> .slider-container']: {
      height: '100%',
    },
  },
  renderButtonsWrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    margin: theme.spacing(2, 0),
  },
}));

export const CELL_ALIGN_CENTER = 'center';
export const CELL_ALIGN_LEFT = 'left';

export interface CarouselProps<SlidesType = React.ReactNode> {
  slidesToShow: number;
  shouldAutoPlay?: boolean;
  interval?: number;
  children?: SlidesType[];
  breakpointProps?: BreakpointProps;
  hasButtons?: boolean;
  cellAlign?: typeof CELL_ALIGN_LEFT | typeof CELL_ALIGN_CENTER;
  stepSize?: number;
}

function Carousel<SlidesType extends React.JSX.Element>({
  slidesToShow = 1,
  shouldAutoPlay = false,
  interval = 8000,
  children,
  breakpointProps = DEFAULT_BREAKPOINT_PROPS,
  hasButtons = false,
  cellAlign = CELL_ALIGN_LEFT,
  stepSize = 1,
}: CarouselProps<SlidesType>) {
  const { classes } = useStyles();
  const { currentSlide, setCurrentSlide } = useCarouselContext();
  const isPriorityLoadedIdle = useIsPriorityLoadedIdle();

  const SSRFallback = useMemo(
    () => <SlidesSSRWrapper breakpointProps={breakpointProps}>{children}</SlidesSSRWrapper>,
    // Intentionally prevent re-rendering of SSR fallback at not including children
    [breakpointProps, children],
  );

  const handleNextSlide = useCallback(
    () => setCurrentSlide?.((a) => a + stepSize),
    [setCurrentSlide, stepSize],
  );
  const handlePreviousSlide = useCallback(
    () => setCurrentSlide?.((a) => a - stepSize),
    [setCurrentSlide, stepSize],
  );
  const handleBeforeSlide = useCallback(
    (_: any, idx: number) => setCurrentSlide?.(idx),
    [setCurrentSlide],
  );

  if (!children) {
    return null;
  }

  return (
    <>
      {hasButtons ? <SlideButton action="previous" onClick={handlePreviousSlide} /> : null}
      {!isPriorityLoadedIdle ? (
        <SlidesSSRWrapper breakpointProps={breakpointProps}>{children}</SlidesSSRWrapper>
      ) : (
        <Suspense fallback={SSRFallback}>
          <div className={classes.carouselWrapper}>
            <LazyNukaCarousel
              pauseOnHover
              wrapAround
              withoutControls
              autoplay={shouldAutoPlay}
              autoplayInterval={interval}
              beforeSlide={handleBeforeSlide}
              cellAlign={cellAlign}
              renderBottomCenterControls={null}
              slideIndex={currentSlide}
              slidesToShow={slidesToShow}
            >
              {children}
            </LazyNukaCarousel>
          </div>
        </Suspense>
      )}
      {hasButtons ? <SlideButton action="next" onClick={handleNextSlide} /> : null}
    </>
  );
}

export default Carousel;
