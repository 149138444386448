import React from 'react';

import { TEMPLATE_QUERY, BASE_PATH_2_QUERY } from '@glass/common/modules/pages/queryParams';
import Box from '@glass/web/components/base/Box';
import { CONTACT_FIELD_V2 } from '@glass/web/modules/resumes/fieldKeys';

import ResumeBuilderLink from '@glass/shared/components/Link/ResumeBuilderLink/ResumeBuilderLink';
import { builderFirstStepParams } from '@glass/shared/modules/resumes/createBuilderFirstStepParams';

export const CONTACT_DEFAULT_VARIATION = 'contact-default';

type ResumeLinkContainerProps = {
  className?: string;
  clickDestination?: string;
  children: React.ReactNode;
  onClick?: (evt: React.MouseEvent<Element, MouseEvent>) => void | undefined;
  trackProps?: Record<string, unknown>;
  template?: string;
};

function ResumeLinkContainer({
  className,
  clickDestination,
  children,
  onClick,
  trackProps,
  template,
}: ResumeLinkContainerProps) {
  if (!template || clickDestination !== CONTACT_DEFAULT_VARIATION) {
    return (
      <Box className={className} onClick={onClick}>
        {children}
      </Box>
    );
  }

  const templateParams = {
    ...builderFirstStepParams,
    [TEMPLATE_QUERY]: template,
    [BASE_PATH_2_QUERY]: CONTACT_FIELD_V2,
  };
  const resumeBuilderLinkProps = {
    onClick,
    className,
    children,
    params: templateParams,
    trackContext: template,
    trackProps: trackProps || { label: template },
  };

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <ResumeBuilderLink {...(resumeBuilderLinkProps as Record<string, unknown>)} />
  );
}

export default ResumeLinkContainer;
