import { useEffect, useState } from 'react';

export type UseLoadProps = {
  shouldLoad: boolean;
};

const useCarouselLoad = ({ shouldLoad }: UseLoadProps) => {
  const [didLoad, setLoad] = useState(shouldLoad);

  useEffect(() => {
    if (!didLoad && shouldLoad) {
      setLoad(true);
    }
  }, [didLoad, shouldLoad]);

  return didLoad;
};

export default useCarouselLoad;
