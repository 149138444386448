import { COMMA_SPACE_DELIMITER } from '@glass/common/modules/strings/constants';
import { ALL_BREAKPOINTS, LG, SM, XL, XS } from '@glass/web/modules/theme/breakpointKeys';

const defaultOptions = {
  containerSize: '100vw',
  gutter: 24,
  numColumns: 3,
};
const defaultBreakpointOptions = {
  [XS]: {
    edgeGutter: 12,
    numColumns: 1,
  },
  [SM]: {
    numColumns: 2,
  },
  [LG]: {
    containerSize: '1280px',
  },
  [XL]: {
    containerSize: '1280px',
  },
};

interface Options {
  breakpointOptions?: Partial<{
    [key in keyof typeof ALL_BREAKPOINTS]?: {
      edgeGutter?: number;
      numColumns?: number;
    };
  }>;
  containerSize?: string;
  gutter?: number;
  numColumns?: number;
}

const calcImageSizes = ({ breakpointOptions = {}, ...options }: Options = defaultOptions) => {
  return ALL_BREAKPOINTS.map((breakpoint) => {
    const { gutter, edgeGutter, containerSize, numColumns } = {
      ...defaultOptions,
      ...options,
      ...(defaultBreakpointOptions[breakpoint as keyof typeof defaultBreakpointOptions] || {}),
      ...(breakpointOptions[breakpoint as keyof typeof ALL_BREAKPOINTS] || {}),
    };
    return `$only(${breakpoint}) calc((${containerSize} - (2 * ${
      edgeGutter || gutter
    }px) - ((${numColumns} - 1) * ${gutter}px)) / ${numColumns})`;
  }).join(COMMA_SPACE_DELIMITER);
};

export default calcImageSizes;
